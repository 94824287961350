import i18next from 'i18next';
import enLocale from './locales/en.json';

i18next.init({
  fallbackLng: 'en',
  resources: {
    en: {
      translation: enLocale
    }
  },
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  react: {
    useSuspense: true
  }
});

i18next.languages = ['en'];

export default i18next;
