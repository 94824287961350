// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agents-tsx": () => import("./../../../src/pages/agents.tsx" /* webpackChunkName: "component---src-pages-agents-tsx" */),
  "component---src-pages-brokerages-tsx": () => import("./../../../src/pages/brokerages.tsx" /* webpackChunkName: "component---src-pages-brokerages-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-onboard-tsx": () => import("./../../../src/pages/onboard.tsx" /* webpackChunkName: "component---src-pages-onboard-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

