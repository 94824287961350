/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'modern-normalize';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
// import { ThemeWrapper } from './src/styles';
import i18nConfig from './src/i18n';

export const wrapRootElement = ({ element }) => {
  return (
    <I18nextProvider i18n={i18nConfig}>
      {element}
      <div id="modal-root" />
    </I18nextProvider>
  );
};
